import React, { createContext, useReducer, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import shortId from 'shortid';
import { Product, Image } from '../picasso';
import { CartItem } from '../models/interfaces/cart-item';
import initFirebase from '../utils/initFirebase';
import { Notification } from '../models/interfaces/notification';
import {
  saveDesignToLocalStorage,
  getDesignFromLocalStorage,
  saveVariantToLocalStorage,
  getVariantFromLocalStorage,
} from '../utils';

export type ActionType =
  | 'ADD'
  | 'REMOVE'
  | 'SELECT_BUNDLE'
  | 'ADD_BUNDLE_DESIGN';

export interface State {
  items: CartItem[];
  subtotal: number;
  cartId: string;
  bundleId: string;
  bundle: Product[];
}

const ADD: ActionType = 'ADD';
const REMOVE: ActionType = 'REMOVE';
const SELECT_BUNDLE: ActionType = 'SELECT_BUNDLE';
const ADD_BUNDLE_DESIGN: ActionType = 'ADD_BUNDLE_DESIGN';

export const context = createContext({});
initFirebase();

function cartReducer(state, action) {
  switch (action.type) {
    case ADD:
      return {
        ...state,
        items: [
          ...state.items,
          {
            ...action.item,
            quantity: action.quantity,
            totalPrice: Math.floor(action.item.price * action.item.quantity),
          },
        ],
        subtotal: Math.floor(
          state.subtotal + action.item.price * action.item.quantity,
        ),
      };
    case REMOVE:
      return {
        ...state,
        items: [...state.items.filter((x: CartItem) => x.id !== action.itemId)],
        subtotal: Math.floor(state.subtotal - action.item.totalPrice),
      };
    case SELECT_BUNDLE:
      return {
        ...state,
        bundle: [...action.bundle],
      };
    case ADD_BUNDLE_DESIGN:
      return {
        ...state,
        bundle: [
          ...state.bundle.map((product) => {
            if (product.id === action.id) {
              return {
                ...product,
                images: action.images,
              };
            }
            return product;
          }),
        ],
      };
    default:
      return state;
  }
}

export const StateProvider = ({ children }) => {
  const initialState = {
    items: [],
    subtotal: 0,
    cartId: shortId.generate(),
    bundleId: shortId.generate(),
    bundle: [],
    cartKey: '',
  } as State;
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const [error, setError] = useState<string>(null);
  const [saved, setSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<Notification>(null);

  const db = firebase.firestore();
  const ref = db.collection('carts');
  const bundlesRef = db.collection('bundles');

  function resetNotification() {
    setNotification(null);
  }

  function newNotification(notificationObject: Notification) {
    setNotification(notificationObject);
    setTimeout(() => {
      resetNotification();
    }, 3500);
  }

  async function addToCart({
    item,
    quantity,
  }: {
    item: CartItem;
    quantity: number;
  }) {
    try {
      const { cartId } = state;
      const cartRef = ref.doc(cartId);

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const url = `${process.env.KUVA_WOO_URL}/wp-json/cocart/v2/cart/add-item`;
      await fetch(url, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
          id: item.id.toString(),
          // variation_id: item.variationId ? item.variationId.toString() : null,
          variation: item.variationId ? item.variation : {},
          quantity: quantity.toString(),
          item_data: {
            designs: item.designs,
          },
          return_item: true,
        }),
      });

      const itemsRef = cartRef.collection('items');
      await itemsRef.add({
        ...item,
        quantity,
      });

      setSaved(true);
      setError(null);

      dispatch({ type: ADD, item, quantity });
    } catch (e) {
      setSaved(false);
      setError(e);
    }
    return Promise.resolve();
  }

  async function removeFromCart(item: CartItem) {
    try {
      const cartRef = ref.doc(state.cartId);

      const itemsRef = cartRef.collection('items');
      await itemsRef.doc(item.id).delete();

      dispatch({ type: REMOVE, item });
      setSaved(true);
      setError(null);
    } catch (e) {
      setSaved(false);
      setError(e);
    }
  }

  async function selectBundle(products: Product[]) {
    try {
      const bundleRef = bundlesRef.doc(state.bundleId);

      const itemsRef = bundleRef.collection('items');
      await itemsRef.add(products);

      dispatch({ type: SELECT_BUNDLE, products });
      setSaved(true);
      setError(null);
    } catch (e) {
      setSaved(false);
      setError(e);
    }
  }

  async function setBundle(bundleId: string) {
    try {
      const products = [];
      const bundleRef = bundlesRef.doc(bundleId).collection('items');

      const snapshot = await bundleRef.get();
      snapshot.forEach((x) => products.push(x.data()));

      dispatch({ type: SELECT_BUNDLE, products });
      setSaved(true);
      setError(null);
    } catch (e) {
      setSaved(false);
      setError(e);
    }
  }

  function addBundleDesign(id: string, images: Image[]) {
    dispatch({ type: ADD_BUNDLE_DESIGN, id, images });
  }

  function saveDesign(id, design) {
    return saveDesignToLocalStorage(id, design);
  }

  function getDesign(id: string) {
    return getDesignFromLocalStorage(id);
  }

  function saveVariant(productId: string, variant: string) {
    return saveVariantToLocalStorage(productId, variant);
  }

  function getVariant(productId: string) {
    return getVariantFromLocalStorage(productId);
  }

  return (
    <context.Provider
      value={{
        state,
        notification,
        newNotification,
        addToCart,
        removeFromCart,
        selectBundle,
        setBundle,
        addBundleDesign,
        setLoading,
        saveDesign,
        getDesign,
        saveVariant,
        getVariant,
        error,
        saved,
        loading,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default context;
