export const saveDesignToLocalStorage = (id: string, design) => {
  try {
    localStorage.setItem(`design-${id}`, JSON.stringify(design));
  } catch (error) {
    localStorage.clear();
  }
};

export const getDesignFromLocalStorage = (id: string) =>
  JSON.parse(localStorage.getItem(`design-${id}`));

export const saveVariantToLocalStorage = (productId: string, variant: string) =>
  localStorage.setItem(`variant-${productId}`, JSON.stringify(variant));

export const getVariantFromLocalStorage = (productId: string): string =>
  JSON.parse(localStorage.getItem(`variant-${productId}`));
