import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

// const api = new WooCommerceRestApi({
//   url: process.env.WOO_URL,
//   consumerKey: process.env.WOO_CONSUMER_KEY,
//   consumerSecret: process.env.WOO_CONSUMER_SECRET,
//   version: 'wc/v3',
// });

const api = new WooCommerceRestApi({
  url: process.env.KUVA_WOO_URL,
  consumerKey: 'ck_07e520d1a79e72621d825f6dc480838548a75ba7',
  consumerSecret: 'cs_137e99aee2e1543af0eed908f32dd472d0830d49',
  version: 'wc/v3',
});

export default api;
